import VacancyCard from "@/components/vacancy-card/index.vue";
import LinkCard from "@/components/link-card/index.vue";
import img1 from "@/assets/images/home/chief.png";
import img2 from "@/assets/images/home/market.png";
import img3 from "@/assets/images/home/penguin.png";
import img4 from "@/assets/images/home/smartchain.png";
import img5 from "@/assets/images/home/whitecoin.png";
export default {
  name: "HomeView",
  components: {
    VacancyCard,
    LinkCard
  },

  data() {
    return {
      xtIntroduce1: "XT.COM Exchange was established in 2018 and registered in Seychelles. Its operating headquarters is located in Dubai. It has operation centers in Singapore, Seoul and other countries and regions, and its business covers the world.",
      xtIntroduce2: "XT.COM is a comprehensive trading platform that supports 100+ high-quality currencies and 300 trading pairs. It has a rich variety of transactions such as currency transactions, leveraged transactions, OTC transactions, contract transactions, and credit card purchases. Provide users with the safest, most efficient and professional digital asset investment services.",
      xtIntroduce3: "XT. COM is committed to diversity in its workforce and is proud to be an Equal Opportunity Employer. All qualified applicants will receive consideration for employment without regard to race, color, religion, creed, gender, national origin, age, disability, veteran status, sex, gender expression or identity, sexual orientation or any other basis protected by applicable law.",
      imgurl1: img1,
      title1: "Chief Positions",
      imgurl2: img2,
      title2: "Marketing Positions",
      imgurl3: img3,
      title3: "Penguin",
      imgurl4: img4,
      title4: "SmartChain",
      imgurl5: img5,
      title5: "Whitecoin",
      allcontent1: [{
        key: 1,
        linkUrl: "/detail",
        jobType: "chief",
        detail: "ceoDetail",
        content: "CEO in XT.COM"
      }, {
        key: 2,
        linkUrl: "detail",
        jobType: "chief",
        detail: "cmoDetail",
        content: "CMO in XT.COM"
      }],
      allcontent2: [{
        key: 1,
        linkUrl: "/detail",
        jobType: "marketing",
        detail: "writer",
        content: "Content Writer_Copywriter JD"
      }, {
        key: 2,
        linkUrl: "/detail",
        jobType: "marketing",
        detail: "dataAnalyze",
        content: "Data Analyze JD"
      }, {
        key: 3,
        linkUrl: "/detail",
        jobType: "marketing",
        detail: "digitalMarketing",
        content: "Digital Marketing JD"
      }, {
        key: 4,
        linkUrl: "/detail",
        jobType: "marketing",
        detail: "directorofMarketing",
        content: "Director of Marketing JD"
      }, {
        key: 5,
        linkUrl: "/detail",
        jobType: "marketing",
        detail: "publicRelations",
        content: "Public Relations and Branding JD"
      }, {
        key: 6,
        linkUrl: "/detail",
        jobType: "marketing",
        detail: "seo",
        content: "SEO and SEM JD"
      }, {
        key: 7,
        linkUrl: "/detail",
        jobType: "marketing",
        detail: "userGrowth",
        content: "User Growth and Retention"
      }],
      allcontent3: [{
        key: 1,
        linkUrl: "/detail",
        jobType: "penguin",
        detail: "penguinCEO",
        content: "Penguin CEO"
      }, {
        key: 2,
        linkUrl: "/detail",
        jobType: "penguin",
        detail: "penguinCMO",
        content: "Penguin CMO"
      }, {
        key: 3,
        linkUrl: "/detail",
        jobType: "penguin",
        detail: "penguinCTO",
        content: "Penguin CTO"
      }],
      allcontent4: [{
        key: 1,
        linkUrl: "/detail",
        jobType: "smartChain",
        detail: "smartChainCEO",
        content: "XT SmartChain CEO"
      }, {
        key: 2,
        linkUrl: "/detail",
        jobType: "smartChain",
        detail: "smartChainCMO",
        content: "XT SmartChain CMO"
      }],
      allcontent5: [{
        key: 1,
        linkUrl: "/detail",
        jobType: "whitecoin",
        detail: "whitecoinCEO",
        content: "Whitecoin CEO"
      }, {
        key: 2,
        linkUrl: "/detail",
        jobType: "whitecoin",
        detail: "whitecoinCMO",
        content: "Whitecoin CMO Job Description"
      }, {
        key: 3,
        linkUrl: "/detail",
        jobType: "whitecoin",
        detail: "whitecoinCTO",
        content: "Whitecoin CTO"
      }]
    };
  }

};