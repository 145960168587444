import { ref } from "vue";
import Clipboard from "clipboard";
import "element-plus/es/components/message/style/css";
import { ElDialog, ElMessage } from "element-plus";
export default {
  name: "apply-job",
  components: {
    ElDialog
  },

  setup() {
    const copyText = ref(null);
    return {
      copyText
    };
  },

  data() {
    return {
      showModal: false,
      text: "https://www.linkedin.com/in/lily-duan-a82068217/dsf34363gsdvfvsdsc",
      text1: "global.hr@xt.com",
      scrollTop: "1000"
    };
  },

  mounted() {
    window.addEventListener("message", event => {
      try {
        const dataObj = JSON.parse(event.data);

        if (dataObj.type && dataObj.type === "viewChanged") {
          const params = dataObj.params;
          this.scrollTop = params.scrollTop;
        }
      } catch (e) {
        console.error("【iframeLogErr】%o", e);
      }
    });
  },

  methods: {
    copy(event) {
      let clipboard = new Clipboard(this.copyText, {
        container: document.getElementById("copy")
      });
      clipboard.on("success", () => {
        ElMessage({
          offset: this.scrollTop,
          type: "success",
          message: "copy success"
        });
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        clipboard.destroy();
      });
      clipboard.onClick(event);
    }

  }
};