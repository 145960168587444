export const writer = {
  name: "Content Writer/Copywriter",
  detail: [
    {
      title: "Responsibilities",
      content: [
        "Work with marketing team members to develop strategy for all of the company's content channels (mainly social media);",
        "Edit, polish, proofread, output a wide range of content;",
        "Standardize the brand, quality and tone of all published content with reference to the company's brand positioning;",
        "Create and implement content guidelines/scripts to ensure consistent voice and content output across global and local channels;",
        "Interpret copywriting briefs to understand posting requirements;",
        "Collaborate within the company on drafting out presentation scripts;",
        "write clear, persuasive, original copy through the various iterations of the project;",
        "Creating & editing of testimonial templates;",
        "Collaborate with internal partners to interpret project briefs and develop relevant concepts into content;",
        "Ensure that all copies are proofed and ready for delivery or posting;",
        "Prepare files and concept boards for review and presentation;",
        "Thrive in a fast-paced, collaborative environment with a high-volume workload often requiring short turnaround times;",
        "Write original copy and edit content for a range of, but not limited to corporate marketing and communications materials;",
        "Present copy concepts and final deliverables to the internal team, and to external representative as needed;",
        "Revise copy based on internal and external feedback/direction",
      ],
    },
    {
      title: "Requirements",
      content: [
        "Bachelor Degree or above in Mass Communications, Languages or any other equivalent;",
        "At least 3 years experience in editing and content output;",
        "Preferably Junior Executive specialized in Journalist/Editor or equivalent;",
        "Basic knowledge of cryptocurrency and blockchain;",
        "Being able to read XT.COM’s investment research and incorporate it into your copywriting will be a strong plus;",
        "Sensitive to cryptocurrency and financial market, knowledge of economy, finance and trade is preferred;",
        "Strong sense of responsibility; Strategic, logical thinking and able to make sound decisions;",
        "Bilingual English/Mandarin, able to read and output English content easily is a must;",
        "Experience in content marketing, digital marketing and integrated marketing is preferred.",
      ],
    },
  ],
};

export const dataAnalyze = {
  name: "Data Analyze",
  detail: [
    {
      title: "Responsibilities",
      content: [
        "Performs data analysis, identifies business needs, defines requirements, documents processes and procedures, and advises on ways to be more efficient;",
        "Create new models based on understanding of how data-driven changes to process, products, services, software, and hardware can improve efficiencies and add value;",
        "Create detailed business analysis, outlining problems, identifying gaps in existing functionality, opportunities, and solution recommendations;",
        "Develop project or product rationale and perform scoping assessments to determine feasibility;",
        "Develop a comprehensive requirement specification that will determine the estimate of cost, time, and resources to deploy solutions;",
        "Ensure consistency and traceability between user requirements, functional specifications, and testing & validation. Support validation and testing as appropriate;",
        "Identify, analyze and interpret trends or patterns in complex data sets;",
        "Develop, maintain and coach automated reports and dashboards using Power BI and Power Query;",
        "Work with both functional and technical project personnel, providing in-depth knowledge of BI information delivery/presentation methodology;",
        "Conduct data analysis to support Product and Business Leaders in business decisions and requirements;",
        "Develop and automate reports for analytical purposes;",
        "Collaborate with other Product Development teams to understand their business needs, formulate and complete end-to-end analysis that includes data gathering, analysis, ongoing scaled deliverables and presentations;",
        "Support key decision makers to improve products and services.",
      ],
    },
    {
      title: "Requirements",
      content: [
        "Minimum Bachelor in Mathematics, Statistics, Finance, Computer Science, Information Technology or other equivalent qualifications;",
        "Experience in FinTech/Crypto Industry is an added advantage;",
        "3 years in IT Industry & Project Management (Applications, Data analysis, End user experience, etc.);",
        "Experience working with statistical software (e.g. R, Python, Excel, etc.) Basic statistical principles and practical applications;",
        "Experience in scripting with SQL.",
      ],
    },
    {
      title: "Skills",
      content: [
        "A/B and Multivariate testing management",
        "Data visualization (such as Tableau, Excel, etc)",
        "Ability to translate analysis results into business recommendations and insights",
        "Ability to present the insights clearly to technical and non-technical audiences",
        "Strong written, verbal communication and presentations skills",
      ],
    },
  ],
};

export const digitalMarketing = {
  name: "Digital Marketing",
  detail: [
    {
      title: "Responsibilities",
      content: [
        "Plan, design, execute, manage and evaluate the entire digital marketing campaigns on Social Media Platforms such as Facebook, Instagram, Google Search Engine Marketing;",
        "Develop engaging, creative, innovative content for regularly scheduled posts that enlighten audiences and promote brand-focused messages;",
        "Design, plan, implement, manage and analyze digital media marketing activities across the range of digital marketing channels, such as SEO, SEM, Social Media, Email, optimizing landing pages and user funnels;",
        "Assist in planning and execution of marketing initiatives to drive brand awareness;",
        "Prepare, execute, manage and optimise digital marketing campaigns;",
        "In-depth analysis of the ad campaign of what works and also necessary suggestions for improvement of the campaign;",
        "To assist the directors on occasional company’s special projects;",
        "Manage and maintain the company’s owned media including websites, mobile apps, and email/CRM software;",
        "Manage the creation of relevant and engaging digital content for publishing onto various digital platforms;",
        "Set up conversion, pixel, and event tracking on the website and across various digital platforms;",
        "Conduct research on market trends, brand’s audiences and competitors, and end-to-end consumer journey to drive engagements and conversions.",
      ],
    },
    {
      title: "Requirements",
      content: [
        "A Degree or Diploma in Communications, Marketing, Public Relations or related discipline;",
        "3-5 years experience in Marketing or marketing agencies;",
        "Basic knowledge of cryptocurrency and blockchain;",
        "Self-motivated with a results-driven approach;",
        "Team player;",
        "Excellent attitude and hunger to learn and accept feedback;",
        "Good communication skills between internal stakeholders and customers;",
        "Ability to manage multiple projects simultaneously;",
        "Proven critical thinking skills, with the ability to prioritize under pressure;",
        "Comfortable in a high-volume, fast-paced environment, with a desire to grow;",
        "Effective listening;",
        "Familiar with basic design functions (Adode Photoshop, Illustrator, Canvaa);",
        "Experience with digital analytics tools (Google Analytics, Facebook Business Suite) and CMS (wordpress) preferred;",
        "High-energy and passionate in integrated marketing communications.",
      ],
    },
    {
      title: "Job advantages:",
      content: [
        "Be a part of the FinTech industry of the future. Be part of the fast growing, challenging, and unique work of the leading companies in the industry",
        "International working environment and relatively flat organizational structure",
      ],
    },
  ],
};

export const directorofMarketing = {
  name: "Director of Marketing",
  detail: [
    {
      title: "Marketing & Branding",
      content: [
        "Participate in the development of branding and corporate identity initiatives;",
        "Develop and implement marketing plans, including promotional calendars and programs, new product introductions and other marketing projects;",
        "Plan and control the departmental budget and costs to ensure performance;",
        "Monitor and manage product branding plans;",
        "Establish and grow a target market share;",
        "Offer expert advice to develop innovative advertising, public relations, social media and events;",
        "Provide Guidance to the team member on negotiating with media partners to guarantee advantageous contracts;",
        "Work with new business development team and sales teams on brand initiatives;",
        "Manage agency providers, consultants, freelancers and other suppliers, including contract negotiation and management;",
        "Supervise marketing team, including training, workloads, schedules and deadlines;",
        "Develop, build and manage digital marketing;",
        "Oversee the operation of Company’s website, email marketing program and provide analytics review.",
      ],
    },
    {
      title: "Public Relations & Communication",
      content: [
        "Manage all external communications and public affairs, including media and public relations (news and editorial coverage, white papers, press releases and articles); financial communications, strategic executive communications; crisis communications; and Company website communications;",
        "Manage the Company's corporate communications via the website, social media channels, etc. Update and continuously improve Company website and social media efforts ensuring that key messages and communications are consistent;",
        "Lead strategic communications development, including, developing communications plan to take advantage of key Company milestones, preparing press releases, presentation decks, and earnings releases, creating management Q&As, conference call scripts, and business descriptions/other information for securities filings, and the Company website;",
        "Serve as a primary person to handle incoming inquiries from the media and investors;",
        "Establish strong internal relationships and effective communication with supporting business and leadership within the organization;",
        "Manage an internal team along with an external set of advisors to successfully meet group objectives;",
        "Experience in managing and overseeing contract PR agencies and vendors;",
        "Plans and executes all web, SEO/SEM, database marketing, email, social media, and display advertising campaigns;",
        "Plans, executes, and measures digital marketing campaigns. Collaborates with internal teams to create landing pages and optimize user experience;",
        "Optimizing website keywords/text to drive high search engine rankings and placing pay-per-click ads that display based on search criteria entered;",
        "Develops your team and improve their performance through coaching and feedback, and creates performance and development goals for colleagues - recognise good performance;",
        "Maintains regular contact with media and through media outreach, site inspections for visiting media and journalists;",
        "Ensures that photography, videography, copywriting, external/internal content and communications are in line with brand standards.",
      ],
    },
    {
      title: "Requirements",
      content: [
        "Bachelor's degree and above in Marketing or related field;",
        "Minimum 8 years of professional level experience in investor relations, market and corporate communications, and related areas;",
        "Knowledge on Blockchain and Cryptocurrency is preferred;",
        "Demonstrate excellent communication and interpersonal skills;",
        "Demonstrated experience and leadership in managing a comprehensive strategic communications programs to advance the organizations vision, mission, values, and strategic objectives;",
        "Experience in delivering clear consistent messaging of scientific and clinical concepts across multiple stakeholders / audiences;",
        "Proven track record of developing a positive, long-term relationship with the investment community;",
        "Strong presentation, communication, written and oral skills;",
        "Strong interpersonal skills to develop and foster beneficial relationships.",
      ],
    },
    {
      title: "Job advantages",
      content: [
        "Be a part of the FinTech industry of the future. Be part of the fast growing, challenging, and unique work of the leading companies in the industry",
        "International working environment and relatively flat organizational structure",
      ],
    },
  ],
};

export const publicRelations = {
  name: "Public Relations and Branding",
  detail: [
    {
      title: "Public Relation",
      content: [
        "Ensure alignment of messages for internal and external audiences;",
        "Develop, manage and/or review communications and media strategies;",
        "Build and maintain strong working relationships with key media;",
        "Drive media announcements for XT.COM including media releases, media statements, events and online updates;",
        "Support business units in developing and implementing media-related activities to achieve positive coverage;",
        "Work with marketing team members to develop strategy for all of the company's content channels (mainly social media);",
        "Edit, polish, proofread, output a wide range of content;",
        "Create and implement content guidelines/scripts to ensure consistent voice and content output across global and local channels;",
        "Analyze content properties, traffic, conversion rates, and user behavior maps and incorporate content strategies;",
        "Create high-quality content that popularizes and energizes our user community, attracts new audiences and increases lead generation and conversion rates;",
        "Manage regional media relations, issues management and KPI tracking, in partnership with country level communications lead and with the regional PR agency (where applicable) according to business requirements;",
        "Conduct media monitoring and manage crisis alerts;",
        "Provide strategic communications counsel to business units;",
        "Work with key stakeholders, including senior management, on issues and crisis management;",
        "Craft compelling messages and speeches for key management personnel;",
        "Develop Annual Reports and other investor communications and corporate communications material.",
      ],
    },
    {
      title: "Brand Management",
      content: [
        "Monitor and review ongoing brand implementation and application;",
        "Promote consistent, effective & efficient brand communication across all properties;",
        "Brand guardianship;",
        "Consolidate & report work & material from all countries & properties;",
        "Promote knowledge sharing, best practices and brand managers’ development globally.",
      ],
    },
    {
      title: "Requirements",
      content: [
        "Degree in media, public relations, corporate communications or related field required;",
        "At least 3 years of public relations, corporate communications or related experience;",
        "Strong integrated communications background;",
        "Strong writing and presentation skills;",
        "Plan, communicate, and execute PR campaigns;",
        "Identify PR opportunities and come up with ideas to maximize exposure in emerging market countries;",
        "Respond to media inquiries;",
        "Develop and execute innovative marketing strategies and campaigns to increase company’s branding and market shares;",
        "Consistently review company’s brand positioning to strengthen brand presence;",
        "A good understanding of crypto and local regulations would be an advantage;",
        "Independent and have a strong attention to detail;",
        "Ability to adapt quickly and shift priorities based on unexpected business demands.",
      ],
    },
  ],
};

export const seo = {
  name: "SEO & SEM",
  detail: [
    {
      title: "Responsibilities",
      content: [
        "Manage & strategize organic search traffic and organic keyword ranking campaigns on Google search engine;",
        "Observe and analyze trends in SEO campaigns to regularly provide insightful and actionable data;",
        "Review and analyze company sites: conduct competitive analyses, powerful keyword research, organic ranking tracking, and web monitoring;",
        "Present and execute strategies for growth, optimizations, back-linking, and areas of improvement for client campaigns;",
        "Recommend changes to website architecture, content, internal linking, and other factors to improve SEO positions for target keywords and increase organic traffic growth;",
        "Assume full ownership of assigned campaigns to ensure the delivery of KPIs;",
        "Collaborate with internal teams to complement and enhance overall marketing campaign performance;",
        "Provide updates, alerts, and reports on campaigns’ performance;",
        "Keep pace with digital marketing landscape changes & disseminate learning throughout the entire organization;",
        "Planning and execution of SEO campaigns: backend set up, on-site optimizations, off-site optimizations, and measuring impact and effectiveness;",
        "Analyzing market research and industry, competitor case studies, and other various creative marketing techniques based on the research results;",
        "Keep up to date on SEO news and updates, Google’s algorithm updates, and various SEO strategies and recommendations to test and implement them.",
      ],
    },
    {
      title: "Requirements",
      content: [
        "Bachelor degree or above, major in Marketing/Computer Science is preferred;",
        "At least 3 year experience in SEO/SEM or the digital marketing industry;",
        "Experience in FinTech and Crypto industry is a plus;",
        "Strong hands-on experience with organic search platforms such Google Analytics, Google Search Console, WordPress, Ahrefs, ScreamingFrog, SE Ranking, and other SEO platforms are highly desirable;",
        "Analytical, performance-driven, meticulous with data, strong research and communication skills;",
        "Good organizational and time management skills;",
        "Adaptable, teachable, a problem solver, and able to handle multiple priorities;",
        "Inquisitive, self-starter, ability to self-manage and to work independently with little supervision while meeting expectations – and able to collaborate well within a team;",
        "Working knowledge of search engine optimization algorithms, best practices or have a good understanding of the basics;",
        "Able to create relevant, engaging, and effective content and/or editing for websites, services, and programs pages, meta page descriptions, blog articles, etc.;",
        "Must be proficient in MS Office and Google e.g., Excel Sheets, Word Documents and PowerPoint Presentations;",
        "Google Analytics certified individuals, WordPress & Shopify familiarity (or other content management systems e.g., WIX, Squarespace, PrestaShop, Umbraco, Joomla), and basic knowledge of HTML, J-SON LD or CSS are preferred.",
      ],
    },
  ],
};

export const userGrowth = {
  name: "User Growth and Retention",
  detail: [
    {
      title: "Responsibilities ",
      content: [
        "Design the promotion mechanism and integrated marketing campaign;",
        "Own the hands-on execution of all user acquisition and activation steps and in-app / webpage marketing campaigns (building & sending emails, pulling audience segments, A/B testing & reporting etc.), working closely with the tech teams; ",
        "Design regional growth financial models, scale and automate growth processes to make it leaner, more efficient and on a start-up mode;",
        "Establishing operational excellence through working closely with country teams to implement regional standard operating procedures;",
        "Setting measurable goals across the Promotional program function that are linked to the company’s strategic objectives;",
        "Manage and analyze marketing data collecting feedbacks (performance results,conversion rates, traffic, sales etc.) to help shape future marketing strategies.;",
        "Monitor the market trend, create, manage, implement, and refine all marketing,community, and social media campaigns in an ongoing basis;",
        "Identify acquisition, retention, and business opportunities based on data, industry trends,and competitive environment;",
        "Analyse the effectiveness of various campaigns, and manage spend accountability;",
        "Ideate, test, iterate, and optimize growth via paid acquisition, retargeting, and product optimizations across web / app growth channels;",
        "Provide on-going reporting on campaigns and revenue impact;",
        "Ensure high quality and error-free implementation.",
      ],
    },
    {
      title: "Requirements",
      content: [
        "Bachelor’s degree in Business Administration/Business Analytics/Economics or related fields;",
        "At least 3 years in user acquisition and activation experience;",
        "Data-driven, proficient at insight analysis to catch business opportunities and execute operational strategy in a short timespan;",
        "Commercial and marketing sense, good at competition analysis to identify strategic opportunities;",
        "Strong organizational, communication, and stakeholder management skills;",
        "Independent and self-driven, record of execution to the last mile;",
        "Knowledge of SQL is highly preferred, Python and other computer languages are a plus;",
        "Relevant acquisition and conversion marketing experience, preferably growing a significant mobile customer base;",
        "Experience in derivatives crypto exchange will add advantages;",
        "Experience in analytical and data visualization tools such as Tableau, Google Analytics etc.;",
        "Experience in working with cross-functional team including Product, Design and Data team to build and measure campaigns in owned marketing channels that interact with the core product;",
        "Analytical and process-oriented mindset with good business sense;",
        "Passion in crypto industry.",
      ],
    },
    {
      title: "Job advantages",
      content: [
        "Be a part of the FinTech industry of the future. Be part of the fast growing,challenging, and unique work of the leading companies in the industry",
        "International working environment and relatively flat organizational structure Enjoy great career development opportunities in a fast growing company We regret to inform that only the successful candidates will be notified.",
      ],
    },
  ],
};
