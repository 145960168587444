export const smartChainCEO = {
  name: "Chief Executive Officer (Global / Full-time Onsite)",
  detail: [
    {
      title: "Responsibilities",
      content: [
        "Set the vision, mission and strategy for the company along with the Board",
        "Lead, in conjunction with the Board, the development of the company’s strategy",
        "Monitor and evaluate the implementation of strategies, policies, management performance criteria and business plans",
        "Ensure that the company complies with all relevant laws, regulations and codes of best business practice",
        "Regularly review processes and procedures to ensure the effectiveness of its internal systems in control, so that its decision-making capability and the accuracy of its reporting and financial results are timely maintained at a high level",
        "Identify key risk areas and key performance indicators of the business and monitor these factors",
        "Ensure the company is appropriately organized and staffed and hire and terminate staff as necessary to enable the achievement of the approved strategy",
        "Ensure that expenditures of the company are within the authorized annual budget",
        "Communicate effectively with shareholders, employees, Government authorities, other stakeholders and the public",
        "Ensure that the Directors are properly informed and that sufficient information is provided to the Board to enable the Directors to form appropriate judgments",
        "Abide by specific internally established control systems and authorities, to lead by personal example and encourage all employees to conduct their activities in accordance with all applicable laws and the company’s standards and policies.",
      ],
    },
    {
      title: "Requirements",
      content: [
        "BS/MS in Computer Science or related field/equivalent practical experience",
        "5+ years of experience in hiring/termination and performance management of teams of size 100+ in product based companies.",
        "10+ years of experience leading engineering teams, including 2+ years of experience in managing senior engineering managers or 2nd level managers.",
        "10+ years of work experience architecting, developing, and launching software products and/or services",
        "Experience in handling consumer and growth engineering teams in 500+ people organizations.",
        "Strong analytic skills and a demonstrated sense of ownership and accountability.",
        "Excellent communication and interpersonal skills and the ability to work with a wide variety of people.",
        "Strong organizational skills, both for yourself and the team",
        "Business acumen: Proven understanding of business dynamics, goals, and product strategy. Knowledge of how Engineering fits into XT.COM overall business.",
        "The ability to balance business needs, a sense of urgency, conflicting constraints, and shipping high quality and pragmatic solutions in a fast-moving and quickly-growing company.",
        "BS/MS in Computer Science or related field/equivalent practical experience",
      ],
    },
    {
      title: "Commitment to Equal Opportunity",
      content:
        "XT Smart Chain is committed to diversity in its workforce and is proud to be an Equal Opportunity Employer. All qualified applicants will receive consideration for employment without regard to race, color, religion, creed, gender, national origin, age, disability, veteran status, sex, gender expression or identity, sexual orientation or any other basis protected by applicable law.",
    },
  ],
};

export const smartChainCMO = {
  name: "Chief Marketing Officer (CMO) (Global / Full-time Onsite)",
  detail: [
    {
      title: "Responsibilities",
      content: [
        "Own critical global marketing operations initiatives including the product marketing launch calendar, executive updates, global marketing planning and budgeting process and more",
        "Be the champion for global operational excellence in delivering strategic recommendations that enables us to become‘Best-In-Class’",
        "Be accountable for a portfolio of work that furthers strategic company objectives. Manage multiple complex, strategic programs and/or projects to successful completion",
        "Drives strategic alignment across cross-functional teams to succeed against company-wide goals",
        "Collaborate with external & internal partners (Vendors, Product Management, Finance, Legal Compliance etc.) to gather business requirements and define program objectives",
        "Collaborate with Global marketing teams to help facilitate management of global initiatives such as localization and other global requirements",
        "Build, nurture, and guide a multi-disciplinary team, ensuring realistic and reliable staffing plans across various projects, focusing on the availability and commitment of appropriately skilled resources",
      ],
    },
    {
      title: "Requirements",
      content: [
        "8+ years of experience in strategic program management in support of marketing or within marketing operations, ideally within a fintech or consumer technology company",
        "2+ years of experience in building and managing high performing teams",
        "Exceptional project management skills with experience gaining consensus and driving deliverables with individuals inside and outside the organization",
        "Ability to communicate business problems in simple narrative format",
        "Demonstrates ownership, resourcefulness, flexibility, willingness, and patience necessary to lead and adapt within a hyper growth company and industry",
      ],
    },
    {
      title: "Job advantages",
      content: [
        "Be a part of the FinTech industry of the future. Be part of the fast growing, challenging, and unique work of the leading companies in the industry",
        "International working environment and relatively flat organizational structure",
      ],
    },
    {
      title: "Commitment to Equal Opportunity",
      content:
        "XT Smart Chain is committed to diversity in its workforce and is proud to be an Equal Opportunity Employer. All qualified applicants will receive consideration for employment without regard to race, color, religion, creed, gender, national origin, age, disability, veteran status, sex, gender expression or identity, sexual orientation or any other basis protected by applicable law.",
    },
  ],
};
