import { ref } from "vue";
import Clipboard from "clipboard";
import "element-plus/es/components/message/style/css";
import { ElMessage } from "element-plus";
export default {
  name: "commitment-detail",

  data() {
    return {
      text: "https://www.linkedin.com/in/lily-duan-a82068217/dsf34363gsdvfvsdsc",
      text1: "global.hr@xt.com",
      screenTop: 0
    };
  },

  mounted() {
    window.addEventListener("message", event => {
      try {
        const dataObj = JSON.parse(event.data);

        if (dataObj.type && dataObj.type === "viewChanged") {
          const params = dataObj.params;
          this.scrollTop = params.scrollTop;
        }
      } catch (e) {
        console.error("【iframeLogErr】%o", e);
      }
    });
  },

  setup() {
    const copyText = ref(null);
    return {
      copyText
    };
  },

  methods: {
    copy(event) {
      let clipboard = new Clipboard(this.copyText, {
        container: document.getElementById("copy")
      });
      clipboard.on("success", () => {
        ElMessage({
          type: "success",
          message: "copy success",
          offset: this.scrollTop - 50
        });
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        clipboard.destroy();
      });
      clipboard.onClick(event);
    }

  }
};