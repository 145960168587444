export const ceoDetail = {
  name: "Chief Executive Officer (Global / Full-time Onsite)",
  detail: [
    {
      key: 1,
      title: "Responsibilities",
      content: [
        "Set the vision, mission and strategy for the company along with the Board",
        "Lead, in conjunction with the Board, the development of the company’s strategy",
        "Monitor and evaluate the implementation of strategies, policies, management performance criteria and business plans",
        "Ensure that the company complies with all relevant laws, regulations and codes of best business practice",
        "Ensure that the company complies with all relevant laws, regulations and codes of best business practice",
        "Identify key risk areas and key performance indicators of the business and monitor these factors",
        "Ensure the company is appropriately organized and staffed and hire and terminate staff as necessary to enable the achievement of the approved strategy",
        "Ensure that expenditures of the company are within the authorized annual budget",
        "Communicate effectively with shareholders, employees, Government authorities, other stakeholders and the public",
        "Ensure that the Directors are properly informed and that sufficient information is provided to the Board to enable the Directors to form appropriate judgments",
        "Ensure that the Directors are properly informed and that sufficient information is provided to the Board to enable the Directors to form appropriate judgments",
      ],
    },
    {
      key: 2,
      title: "Requirements",
      content: [
        "BA / BS degree and above",
        "Prior experience in crypto exchange company, smart contracts, and Smart Chain is a must",
        "Have zero to one build experience, either as the founder of start-up, an early-stage CEO, or of a new and unproven Business Unit of a larger corporate",
        "Strong experience in management consulting or in a generalist role at a technology company (role dependent upon experience level)",
        "Significant P&L ownership experience, most likely as a CEO or the leader of a significant Business Unit within an enterprise software/ technology/Crypto organization",
        "Solid leadership at least ~50 people, including Engineering and Product Leaders, with a demonstrable track record of having driven above-benchmark employee engagement, and an exceptional culture",
        "Solid leadership at least ~50 people, including Engineering and Product Leaders, with a demonstrable track record of having driven above-benchmark employee engagement, and an exceptional culture",
        "High levels of compliance and business ethics",
        "Comfort working in a high growth, constantly changing environment",
      ],
    },
    {
      key: 3,
      title: "Commitment to Equal Opportunity",
      content:
        "XT. COM is committed to diversity in its workforce and is proud to be an Equal Opportunity Employer. All qualified applicants will receive consideration for employment without regard to race, color, religion, creed, gender, national origin, age, disability, veteran status, sex, gender expression or identity, sexual orientation or any other basis protected by applicable law.",
    },
  ],
};

export const cmoDetail = {
  name: "Chief Marketing Officer (Global / Full-time Onsite)",
  detail: [
    {
      title: "Responsibilities",
      content: [
        "Own critical global marketing operations initiatives including the product marketing launch calendar, executive updates, global marketing planning and budgeting process and more",
        "Be the champion for global operational excellence in the marketing org and proactively deliver strategic recommendations that enables us to become ‘Best-In-Class’",
        "Be accountable for a portfolio of work that furthers strategic company objectives. Manage multiple complex, strategic programs and/or projects to successful completion",
        "Drives strategic alignment across cross functional teams to succeed against company-wide goals",
        "Collaborate with external & internal partners (Vendors, Product Management, Finance, Legal Compliance etc.) to gather business requirements and define program objectives",
        "Collaborate with external & internal partners (Vendors, Product Management, Finance, Legal Compliance etc.) to gather business requirements and define program objectives",
        "Build, nurture, and guide a multi-disciplinary team, ensuring realistic and reliable staffing plans across various projects, focusing on the availability and commitment of appropriately skilled resources",
      ],
    },
    {
      title: "Requirements",
      content: [
        "8+ years of experience in strategic program management in support of marketing or within marketing operations, ideally within a fintech or consumer technology company",
        "2+ years of experience in building and managing high performing teams",
        "Exceptional project management skills with experience gaining consensus and driving deliverables with individuals inside and outside the organization",
        "Ability to communicate business problems in simple narrative format",
        "Demonstrates ownership, resourcefulness, flexibility, willingness, and patience necessary to lead and adapt within a hyper growth company and industry",
      ],
    },
    {
      title: "Job advantages",
      content: [
        "Be a part of the FinTech industry of the future. Be part of the fast growing, challenging, and unique work of the leading companies in the industry",
        "International working environment and relatively flat organizational structure",
      ],
    },
    {
      title: "Commitment to Equal Opportunity",
      content:
        "XT. COM is committed to diversity in its workforce and is proud to be an Equal Opportunity Employer. All qualified applicants will receive consideration for employment without regard to race, color, religion, creed, gender, national origin, age, disability, veteran status, sex, gender expression or identity, sexual orientation or any other basis protected by applicable law.",
    },
  ],
};
