import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-58d1a8a3"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "card-content"
};
const _hoisted_2 = {
  class: "apply"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_apply_job = _resolveComponent("apply-job");

  return _openBlock(), _createElementBlock("div", {
    class: "card",
    style: _normalizeStyle({
      background: 'url(' + $props.imgUrl + ')',
      'background-size': 'cover'
    })
  }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", null, [_createElementVNode("h2", {
    style: _normalizeStyle({
      color: $data.descColor
    })
  }, _toDisplayString($props.title), 5), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.content, data => {
    return _openBlock(), _createElementBlock("h5", {
      style: _normalizeStyle({
        color: $data.descColor
      }),
      key: data
    }, _toDisplayString(data), 5);
  }), 128)), _createElementVNode("div", _hoisted_2, [_createVNode(_component_apply_job)])])])], 4);
}