import ApplyJob from "@/components/apply-job/index.vue";
export default {
  name: "abstract-detail",
  components: {
    ApplyJob
  },
  props: {
    imgUrl: String,
    title: String,
    description: {
      type: Array,
      required: false
    }
  },

  data() {
    return {
      content: this.description || ["XT.COM Exchange was established in 2018 and registered in Seychelles. Its operating headquarters is located in Dubai. It has operation centers in Singapore, Seoul and other countries and regions, and its business covers the world.XT.COM is a comprehensive trading platform that supports 100+ high-quality currencies and 300 trading pairs. It has a rich variety of transactions such as currency transactions, leveraged transactions, OTC transactions, contract transactions, and credit card purchases. Provide users with the safest, most efficient and professional digital asset investment services."],
      descColor: "rgba(22, 22, 22, 0.9)"
    };
  },

  mounted() {
    if (this.$route.query.jobType === "whitecoin") {
      this.descColor = "rgba(22, 22, 22, 0.9)";
    } else {
      this.descColor = "rgba(255, 255, 255, 1)";
    }
  }

};