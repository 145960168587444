import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-0ab84ab8"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "detail"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_job_breadcrumb = _resolveComponent("job-breadcrumb");

  const _component_abstract_detail = _resolveComponent("abstract-detail");

  const _component_common_detail = _resolveComponent("common-detail");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_job_breadcrumb), _createVNode(_component_abstract_detail, {
    description: $data.description,
    imgUrl: $data.imgUrl,
    title: $data.title
  }, null, 8, ["description", "imgUrl", "title"]), _createVNode(_component_common_detail, {
    jobDetail: $data.detail
  }, null, 8, ["jobDetail"])]);
}