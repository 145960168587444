// import LinkCard from "@/components/link-card/index.vue";
export default {
  name: "vacancy-card",
  //   components: {
  //     LinkCard,
  //   },
  props: {
    title: String,
    imgUrl: String
  }
};