import CommitmentDetail from "@/components/commitment-detail/index.vue";
import ApplyJob from "@/components/apply-job/index.vue";
export default {
  name: "common-detail",
  props: {
    jobDetail: Object
  },
  components: {
    CommitmentDetail,
    ApplyJob
  },

  data() {
    return {};
  }

};