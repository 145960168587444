import chiefImage from "@/assets/images/detail/bg_ChiefPositions.png";
import marketImage from "@/assets/images/detail/bg_MarketingPositions.png";
import penguinImage from "@/assets/images/detail/bg_Penguin.png";
import smartchainImage from "@/assets/images/detail/bg_SmartChain.png";
import whitecoinImage from "@/assets/images/detail/bg_Whitecoin.png";
import AbstractDetail from "@/components/abstract-detail/index.vue";
import JobBreadcrumb from "@/components/job-breadcrumb/index.vue";
import CommonDetail from "@/components/common-detail/index.vue";
import { ceoDetail, cmoDetail } from "@/assets/js/chiefPositions";
import { writer, dataAnalyze, digitalMarketing, directorofMarketing, publicRelations, seo, userGrowth } from "@/assets/js/marketingPositions";
import { penguinCEO, penguinCMO, penguinCTO } from "@/assets/js/penguin";
import { smartChainCEO, smartChainCMO } from "@/assets/js/smartChain";
import { whitecoinCEO, whitecoinCMO, whitecoinCTO } from "@/assets/js/whitecoin";
const detailData = {
  ceoDetail: ceoDetail,
  cmoDetail: cmoDetail,
  writer: writer,
  dataAnalyze: dataAnalyze,
  digitalMarketing: digitalMarketing,
  directorofMarketing: directorofMarketing,
  publicRelations: publicRelations,
  seo: seo,
  userGrowth: userGrowth,
  penguinCEO: penguinCEO,
  penguinCMO: penguinCMO,
  penguinCTO: penguinCTO,
  smartChainCEO: smartChainCEO,
  smartChainCMO: smartChainCMO,
  whitecoinCEO: whitecoinCEO,
  whitecoinCMO: whitecoinCMO,
  whitecoinCTO: whitecoinCTO
};
export default {
  name: "chief-detail",
  components: {
    AbstractDetail,
    JobBreadcrumb,
    CommonDetail
  },

  data() {
    return {
      imgUrl: chiefImage,
      description: null,
      title: "",
      detail: ceoDetail
    };
  },

  created() {
    const {
      detail,
      jobType
    } = this.$route.query;

    switch (jobType) {
      case "chief":
        this.imgUrl = chiefImage;
        break;

      case "marketing":
        this.imgUrl = marketImage;
        break;

      case "penguin":
        this.imgUrl = penguinImage;
        break;

      case "smartChain":
        this.imgUrl = smartchainImage;
        break;

      case "whitecoin":
        this.imgUrl = whitecoinImage;
        break;

      default:
        break;
    }

    this.detail = detailData[detail];

    if (this.detail && this.detail.description) {
      this.description = this.detail.description;
    }

    if (this.detail && this.detail.name) {
      this.title = this.detail.name;
    }
  }

};