import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-2a216356"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "card-content"
};
const _hoisted_2 = {
  class: "card-content-pic"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  class: "card-content-title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("img", {
    src: $props.imgUrl,
    alt: ""
  }, null, 8, _hoisted_3)]), _createElementVNode("div", _hoisted_4, _toDisplayString($props.title), 1)]);
}