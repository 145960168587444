export const penguinCEO = {
  name: "Chief Executive Officer (CEO) (Global / Full-time Onsite)",
  description: [
    "PENGUIN is a P2P network, providing decentralized storage and communication services. Without permission and privacy, Penguin meets the needs of freedom of speech, data sovereignty and network open market, while ensuring its security through integrity protection, censorship resistance and attack resilience.",
    "We are seeking an exceptional CEO who is passionate about growing a new company or business line with a differentiated product offering. The ideal candidate will have a successful CEO track record of growing a digital startup in the financial services space.",
  ],
  detail: [
    {
      title: "Responsibilities",
      content: {
        "Lead Strategy": [
          "Set the vision for the company and define the overall strategy from launch to scale, including long-term goals",
          "Drive growth, profitability and company value",
          "Develop and implement scalable and efficient strategies to ensure both consistent results and exponential growth",
          "Build Great Talent & Teams",
          "Attract, retain & develop world-class talent, including making recruitment decisions of full leadership team",
          "Cultivate a high-performing, agile, one-team culture",
        ],
        "Execute with Excellence": [
          "Drive day-to-day operations",
          "Align resources to achieve key business priorities",
          "Proactively mitigate business, operational & governance risks",
          "Own the operational budget and manage burn rate while growing the business ahead of market disruptions",
        ],
        "Win in the Marketplace": [
          "Drive continuous improvement",
          "Manage competition and threats in the marketplace, with constant evaluation of financial structure, capital strategy & strategic priorities",
        ],
        "Disrupt & Innovate": [
          "Continue to develop the business model ahead of the market",
          "Provide expert guidance to all functional areas, including operations, sales & product",
        ],
        "Mobilize Stakeholders": [
          "Regularly report updates to Board of Directors and corporate stakeholders regarding progress on strategic priorities, and collaborate with Board of Directors to accelerate progress",
          "Invest in strategic partnerships & relationships to drive growth",
        ],
        "Inspire with Meaning & Purpose": [
          "Serve as chief external spokesperson and public champion for the business",
          "Establish credibility throughout the customer and partner network by authentic engagement to galvanize company to create & drive solutions to core business challenges.",
        ],
      },
    },
    {
      title: "Requirements",
      content: [
        "Bachelor’s degree (Master’s degree preferred)",
        "+7 years of experience (within the last 10 years) leading and scaling a high growth start-up, preferably a company with venture or other funding and 50+ employees",
        "P&L responsibilities at CEO level or head of significant BU",
        "Proven track record of building successful products and high caliber teams from the ground up, ideally in current roles",
        "Prior working experience in developing digital products and solutions or experience in digital transformation projects with a traditional company/industry",
        "Relevant industry experience within financial services",
        "Experience in managing a Board of Directors and corporate stakeholders to keep regular updates on business performance and progress",
      ],
    },
    {
      title: "Commitment to Equal Opportunity",
      content:
        "PENGUIN is committed to diversity in its workforce and is proud to be an Equal Opportunity Employer. All qualified applicants will receive consideration for employment without regard to race, color, religion, creed, gender, national origin, age, disability, veteran status, sex, gender expression or identity, sexual orientation or any other basis protected by applicable law.",
    },
  ],
};

export const penguinCMO = {
  name: "Chief Marketing Officer (CMO) (Global / Full-time Onsite)",
  description: [
    "PENGUIN is a P2P network, providing decentralized storage and communication services. Without permission and privacy, Penguin meets the needs of freedom of speech, data sovereignty and network open market, while ensuring its security through integrity protection, censorship resistance and attack resilience.",
  ],
  detail: [
    {
      title: "Responsibilities",
      content: [
        "Mastermind the strategic planning and system construction for the globalization and promotion of projects. Be fully accountable for the planning, operation, design, promotion and management of the global overseas market",
        "Accurately grasp the market positioning of the project and direct different marketing planning in the light of global market demand and changes, regional characteristics and market information",
        "Create and execute global marketing strategies, tactics, coverage and promotion plans.",
        "Build up and maintain good relationships with the public, such as media, organizations, communities, KOL and users",
        "Develop and review marketing, PR plans and budgets according to the roadmap of the project; oversee the launch process and its effectiveness",
        "Be responsible for the selection of media in each target region and drive overall media partnerships",
        "Fulfil the organization, promotion of the project global marketing department and team-building management.",
      ],
    },
    {
      title: "Requirements",
      content: [
        "Age 30-45, with a bachelor degree in Marketing, or equivalent, and ideally a seasoned management professional in blockchain industry",
        "5+ years of experience in marketing team management, with successful global marketing experience in blockchain projects; deep insight into blockchain and abundant market cooperation resources",
        "Rich experience in overseas marketing, including building overseas teams, and English can be used as working language",
        "A sound track record in leading or being involved in building channels, new overseas branches, marketing policies and other related sales system",
        "A strong sense of responsibility and outstanding compression capability",
      ],
    },
    {
      title: "Commitment to Equal Opportunity",
      content:
        "PENGUIN is committed to diversity in its workforce and is proud to be an Equal Opportunity Employer. All qualified applicants will receive consideration for employment without regard to race, color, religion, creed, gender, national origin, age, disability, veteran status, sex, gender expression or identity, sexual orientation or any other basis protected by applicable law.",
    },
  ],
};

export const penguinCTO = {
  name: "Chief Technology Officer (CTO) (Global / Full-time Onsite)",
  description: [
    "PENGUIN is a P2P network, providing decentralized storage and communication services. Without permission and privacy, Penguin meets the needs of freedom of speech, data sovereignty and network open market, while ensuring its security through integrity protection, censorship resistance and attack resilience.",
  ],
  detail: [
    {
      title: "Responsibilities",
      content: [
        "Monitor the development teams and their projects",
        "Oversee the performance and verify the reliability of the internal systems",
        "Ensure the compliance of security regulations",
        "Manage the projects by setting requirements, goals and timelines",
        "Be involved in future hiring processes and oversee the training of new members",
        "Communicate with stakeholders",
      ],
    },
    {
      title: "Requirements",
      content: [
        "Degree in Engineering, Computer Science or similar fields",
        "Previous experience in working in similar positions",
        "Experience in enterprise architecture",
        "Good knowledge of Agile methodologies",
        "Have great project management skills",
      ],
    },
    {
      title: "Commitment to Equal Opportunity",
      content:
        "PENGUIN is committed to diversity in its workforce and is proud to be an Equal Opportunity Employer. All qualified applicants will receive consideration for employment without regard to race, color, religion, creed, gender, national origin, age, disability, veteran status, sex, gender expression or identity, sexual orientation or any other basis protected by applicable law.",
    },
  ],
};
