import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "@/views/home/index.vue";
import ChiefDetail from "@/views/chief/index.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/detail",
    name: "detail",
    component: ChiefDetail,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    window.parent.postMessage(
      JSON.stringify({
        type: "heightChanged",
        params: { height: document.getElementById("app")?.scrollHeight },
      }),
      "*"
    );
    if (to.query.anchor) {
      return {
        el: "#" + to.query.anchor,
        behavior: "smooth",
      };
    } else {
      window.parent.postMessage(
        JSON.stringify({
          type: "scrollTop",
          params: { value: 0 },
        }),
        "*"
      );
      return { top: 0 };
    }
  },
});

export default router;
