export default {
  name: "link-card",
  props: {
    content: String,
    url: String,
    detail: {
      type: String,
      required: false
    },
    jobType: {
      type: String,
      required: false
    }
  }
};