export const whitecoinCEO = {
  name: "Chief Executive Officer (Global / Full-time Onsite)",
  description: [
    "Whitecoin is a public chain that utilizes interconnection between blockchains through the innovative Multi Tunnel Blockchain Communication Protocol (MTBCP) protocol.",
    "As an essential part of the Whitecoin ecosystem, it adopts the Random Proof of Stake (RPOS) consensus, Whitecoin Axis, Whitecoin Wallet, decentralized mining pools, and smart contract platforms to build a cross-chain blockchain ecological infrastructure.",
  ],
  detail: [
    {
      title: "Responsibilities",
      content: [
        "Set the vision, mission and strategy for the company along with the Board",
        "Lead, in conjunction with the Board, the development of the company’s strategy",
        "Monitor and evaluate the implementation of strategies, policies, management performance criteria and business plans",
        "Ensure that the company complies with all relevant laws, regulations and codes of best business practice",
        "Regularly review processes and procedures to ensure the effectiveness of its internal systems in control, so that its decision-making capability and the accuracy of its reporting and financial results are timely maintained at a high level",
        "Identify key risk areas and key performance indicators of the business and monitor these factors",
        "Ensure the company is appropriately organized and staffed and hire and terminate staff as necessary to enable the achievement of the approved strategy",
        "Ensure that expenditures of the company are within the authorized annual budget",
        "Communicate effectively with shareholders, employees, Government authorities, other stakeholders and the public",
        "Ensure that the Directors are properly informed and that sufficient information is provided to the Board to enable the Directors to form appropriate judgments",
        "Abide by specific internally established control systems and authorities, to lead by personal example and encourage all employees to conduct their activities in accordance with all applicable laws and the company’s standards and policies.",
      ],
    },
    {
      title: "Requirements",
      content: [
        "BA / BS degree and above",
        "Prior experience in crypto exchange company, smart contracts, and Smart Chain is a must",
        "Have zero to one build experience, either as the founder of start-up, an early-stage CEO, or of a new and unproven Business Unit of a larger corporate",
        "Strong experience in management consulting or in a generalist role at a technology company (role dependent upon experience level).",
        "Significant P&L ownership experience, most likely as a CEO or the leader of a significant Business Unit within an enterprise software/ technology/Crypto organization",
        "Solid leadership at least ~50 people, including Engineering and Product Leaders, with a demonstrable track record of having driven above-benchmark employee engagement, and an exceptional culture",
        "Exceptional Board and C-Suite engagement experience - You are resilient and have experience navigating complex stakeholders, e.g. investors, board, commercial partners",
        "High levels of compliance and business ethics",
        "Comfort working in a high growth, constantly changing environment",
      ],
    },
    {
      title: "Commitment to Equal Opportunity",
      content:
        "Whitecoin is committed to diversity in its workforce and is proud to be an Equal Opportunity Employer. All qualified applicants will receive consideration for employment without regard to race, color, religion, creed, gender, national origin, age, disability, veteran status, sex, gender expression or identity, sexual orientation or any other basis protected by applicable law.",
    },
  ],
};

export const whitecoinCMO = {
  name: "Chief Marketing Officer (CMO) (Global / Full-time Onsite)",
  description: [
    "Whitecoin is a public chain that utilizes interconnection between blockchains through the innovative Multi Tunnel Blockchain Communication Protocol (MTBCP) protocol.",
    "As an essential part of the Whitecoin ecosystem, it adopts the Random Proof of Stake (RPOS) consensus, Whitecoin Axis, Whitecoin Wallet, decentralized mining pools, and smart contract platforms to build a cross-chain blockchain ecological infrastructure.",
  ],
  detail: [
    {
      title: "Responsibilities",
      content: [
        "Mastermind the strategic planning and system construction for the globalization and promotion of projects. Be fully accountable for the planning, operation, design, promotion and management of the global overseas market",
        "Accurately grasp the market positioning of the project and direct different marketing planning in the light of global market demand and changes, regional characteristics and market information",
        "Create and execute global marketing strategies, tactics, coverage and promotion plans",
        "Build up and maintain good relationships with the public, such as media, organizations, communities, KOL and users",
        "Develop and review marketing, PR plans and budgets according to the roadmap of the project; oversee the launch process and its effectiveness",
        "Be responsible for the selection of media in each target region and drive overall media partnerships",
        "Fulfil the organization, promotion of the project global marketing department and team-building management.",
      ],
    },
    {
      title: "Requirements",
      content: [
        "Age 30-45, with a bachelor degree in Marketing, or equivalent, and ideally a seasoned management professional in blockchain industry",
        "5+ years of experience in marketing team management, with successful global marketing experience in blockchain projects; deep insight into blockchain and abundant market cooperation resources",
        "Rich experience in overseas marketing, including building overseas teams, and English can be used as working language",
        "A sound track record in leading or being involved in building channels, new overseas branches, marketing policies and other related sales system",
        "A strong sense of responsibility and outstanding compression capability",
      ],
    },
    {
      title: "Commitment to Equal Opportunity",
      content:
        "WHITECOIN is committed to diversity in its workforce and is proud to be an Equal Opportunity Employer. All qualified applicants will receive consideration for employment without regard to race, color, religion, creed, gender, national origin, age, disability, veteran status, sex, gender expression or identity, sexual orientation or any other basis protected by applicable law.",
    },
  ],
};

export const whitecoinCTO = {
  name: "Chief Technology Officer (CTO) (Global / Full-time Onsite)",
  description: [
    "Whitecoin is a public chain that utilizes interconnection between blockchains through the innovative Multi Tunnel Blockchain Communication Protocol (MTBCP) protocol.",
    "As an essential part of the Whitecoin ecosystem, it adopts the Random Proof of Stake (RPOS) consensus, Whitecoin Axis, Whitecoin Wallet, decentralized mining pools, and smart contract platforms to build a cross-chain blockchain ecological infrastructure.",
  ],
  detail: [
    {
      title: "Responsibilities",
      content: [
        "Monitor the development teams and their projects",
        "Oversee the performance and verify the reliability of the internal systems",
        "Ensure the compliance of security regulations",
        "Manage the projects by setting requirements, goals and timelines",
        "Be involved in future hiring processes and oversee the training of new members",
        "Communicate with stakeholders",
      ],
    },
    {
      title: "Requirements",
      content: [
        "Degree in Engineering, Computer Science or similar fields",
        "Previous experience in working in similar positions",
        "Experience in enterprise architecture",
        "Good knowledge of Agile methodologies",
        "Have great project management skills",
      ],
    },
    {
      title: "Commitment to Equal Opportunity",
      content:
        "Whitecoin is committed to diversity in its workforce and is proud to be an Equal Opportunity Employer. All qualified applicants will receive consideration for employment without regard to race, color, religion, creed, gender, national origin, age, disability, veteran status, sex, gender expression or identity, sexual orientation or any other basis protected by applicable law.",
    },
  ],
};
